<template>
  <div class="promotionList">
    <div class="topbar">
      <div class="search">
        <van-search v-model="searchtext" class="searchinput" placeholder="搜索活动标题
        " @search="searchClick" />
        <button type="button" @click="searchClick">搜索</button>
      </div>
    </div>
    <div class="listcont">
      <div class="item" v-for="(item, index) in list" :key="index" @click="actpicClick(item)">
        <div class="top">
          <div class="text">
            <div class="tit">{{ item.share_title }}</div>
            <div class="subtit">{{ item.share_desc }}</div>
          </div>
          <div class="pic"><img :src="item.share_img" alt=""></div>
        </div>
        <!-- <div class="bottom">
          <button type="buttom" @click="actpicClick(item)">海报分享</button>
          <button type="buttom">链接分享</button>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import API from "@/axios";
import { Toast } from "vant";
import { useRouter } from "vue-router";
import wx from 'jweixin-js';
import mitts from "@/bus";

const router = useRouter();

const searchtext = ref("")
const list = ref([])
const page = ref("")

function searchClick() {
  list.value = [],
    page.value = 1
  getinfo()
}

function getinfo() {
  API({
    url: "/web/activity/activityList",
    method: "post",
    data: {
      search: {
        share_title: searchtext.value,
      }
    },
  }).then((res) => {
    if (res.data.status == 200) {
      list.value = res.data.data
    } else {
      Toast.fail(res.data.msg);
    }
  });
}


function actpicClick(val) {
  router.push({
    path: val.path,
    query: val.path_params
  });
}


// 需要使用的JS接口列表
const jsApiListArr = [
  "updateTimelineShareData",
  "updateAppMessageShareData",
  "onMenuShareTimeline",
  "onMenuShareAppMessage",
  'checkJsApi',
  'hideMenuItems',
];

const resData = ref(null)

//初始化微信sdk
function getwxconfig() {
  API({
    url: "/web/wxJs",
    method: "post",
    data: {
      url: encodeURIComponent(window.location.href.split("#")[0]),
      // url: encodeURIComponent("https://saas.zhijierongxing.com"), // 正式上线请替换上面的
    },
  }).then((res) => {
    if (res.data.status == 200) {
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: res.data.data.appId, // 必填，公众号的唯一标识
        timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳
        nonceStr: res.data.data.nonceStr, // 必填，生成签名的随机串
        signature: res.data.data.signature,// 必填，签名
        jsApiList: jsApiListArr
      });

      wx.checkJsApi({
        jsApiList: jsApiListArr,
        success: function () {
          // console.log('wx.checkJsApi:success',res)
          // 以键值对的形式返回，可用的api值true，不可用为false
          // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
        },
        error: function (res) {
          console.log('wx.checkJsApi:error', res)
        }
      });

      wx.error(function (res) {
        console.log('wx.error', res)
        // Toast.fail({
        //   message: '微信JSSDK信息验证失败！'
        // });
      });

      setShare();
    } else {
      Toast.fail({
        message: res.data.msg
      });
    }
  });
}

function setShare(val) {
  API({
    url: "/web/activity/qrcode",
    data: {
      aid: val,
    },
    method: "get",
  }).then((res) => {

    if (res.data.status == 200) {
      resData.value = res.data.data;
      loadWxShare()
    } else {
      Toast.fail(res.data.msg);
    }
  });
}

//加载微信分享组件
function loadWxShare() {

  wx.ready(function () {
    wx.hideMenuItems({
      // 要隐藏的菜单项，只能隐藏“传播类”和“保护类”按钮，所有menu项见附录31);
      menuList: [
        'menuItem:share:timeline', 'menuItem:share:qq', 'menuItem:favorite', 'menuItem:share:QZone', 'menuItem:openWithSafari'
      ]
    })

    let shareTitle = resData.value.share_title;
    let shareDesc = resData.value.share_desc;
    let shareLink = resData.value.share_url;
    let shareImg = resData.value.share_img;

    wx.updateAppMessageShareData({
      title: shareTitle,
      desc: shareDesc,
      link: shareLink,
      imgUrl: shareImg,
      success: function () {
        // 分享成功可以做相应的数据处理
      },
      fail: function (res) {
        console.log('updateAppMessageShareData.fail', res)
      }
    })

    wx.updateTimelineShareData({
      title: shareTitle,
      link: shareLink,
      imgUrl: shareImg,
      success: function () {
        // 分享成功可以做相应的数据处理
      },
      fail: function (res) {
        console.log('updateTimelineShareData.fail', res)
      }
    })

  });

}

onMounted(() => {
  getinfo()
  getwxconfig()
  setTimeout(() => {
    mitts.emit("closeLoading");
  }, 500);
})
</script>

<style lang="less" scoped>
.promotionList {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-flow: column;
  background: #f3f3f3;
  box-sizing: border-box;
}

.topbar {
  background: #fff;
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 20px;
}

.search {
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  display: flex;
  background: #f3f3f3;

  .searchinput {
    flex: 1;
    min-width: 0;
    background: #f3f3f3;
  }

  button {
    font-size: @font-size;
    font-family: "Bold";
    color: @font-color;
    line-height: @font-size;
    width: 80px;
    height: 54px;
    border-radius: 10px;
    background: none;
  }
}

.listcont {
  flex-grow: 1;
  height: 10vh;
  overflow-y: auto;
  padding: 20px;
  box-sizing: border-box;

  .item {
    background: #fff;
    border-radius: 20px;
    box-shadow: 0 0 20px #d7d7d7;
    margin-bottom: 30px;

    .top {
      padding: 20px;
      box-sizing: border-box;
      display: flex;

      .text {
        flex: 1;
        min-width: 0;
        margin-right: 20px;

        .tit {
          font-size: 32px;
          line-height: 32px;
          color: #333;
          margin-bottom: 10px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        .subtit {
          font-size: 28px;
          line-height: 30px;
          color: #999;
        }
      }

      .pic {
        width: 200px;
        height: 160px;
        border-radius: 10px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    .bottom {
      display: flex;
      padding: 20px 0;

      button {
        flex: 1;
        background: none;
        font-size: 32px;
        line-height: 32px;
        border-right: 1px solid #dfdfdf;
      }

      button:last-of-type {
        border-right: 0;
      }
    }
  }
}
</style>